import React, { useEffect } from "react";
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation } from '.'

// Styles
import '../../styles/app.css'

import AOS from 'aos';
import "aos/dist/aos.css";

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);



/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/


const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    const site = data.allGhostSettings.edges[0].node
    const carousel = data.allGhostPost.edges

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);
    
    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
            </Helmet>

            <div className="viewport">

                <div className="viewport-top">
                    {/* The main header section on top of the screen */}
                    <header className="site-head" style={{ ...site.cover_image && { backgroundImage: `url(${site.cover_image})` } }}>
                        <div className="container">
                            <div className="site-mast">
                                <div className="site-mast-left">
                                    <Link to="/">
                                        {site.logo ?
                                            <img className="site-logo" src={site.logo} alt={site.title} />
                                            : <Img fixed={data.file.childImageSharp.fixed} alt={site.title} />
                                        }
                                    </Link>
                                    <label htmlFor="drop" className="menu-toggle menu-toggle-menu"><span className="material-icons">menu</span></label>
                                </div>

                                <div className="site-mast-right">
                                    <nav className="site-nav">
                                        <input type="checkbox" id="drop" />
                                        <ul className="site-nav-left">
                                            {/* The navigation items as setup in Ghost */}
                                            <Navigation data={ site.navigation } secondary_navigation={ site.secondary_navigation } navClass="site-nav-item" />
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </header>

                    { isHome &&
                        <div className="site-banner">
                            <AutoplaySlider
                                play={true}
                                cancelOnInteraction={false} // should stop playing on user interaction
                                interval={6000}
                                className={'site-banner-slider'}
>
                                {  carousel.map(({ node, i }) => (
                                    <div key={ i } data-src={ node.feature_image }>{ node.feature_image }</div>
                                ))}
                            </AutoplaySlider>
                        </div> 
                    }

                    <main className={`site-main`}>
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </main>

                </div>

                <div className="viewport-bottom">
                    {/* The footer at the very bottom of the screen */}
                    
                    { !isHome &&

                        <div className="site-foot-contact-us-image">

                            <div className="site-foot-contact-us" style={{ height: '350px' }}>
                                <div className="overlay"></div>
                                <div className="container">
                                    <a href="/contact-us" className="site-foot-contact-us-button">Contact Us</a>
                                </div>
                            </div>
                        </div> 
                    }

                    <footer className="site-foot">
                        <div className="site-foot-contact-wrapper">
                        <div className="container">
                            <div className="site-foot-contact">
                                <div className="site-foot-left">
                                    {site.logo ?
                                        <img className="site-foot-logo" src={site.logo} alt={site.title} />
                                        : <Img fixed={data.file.childImageSharp.fixed} alt={site.title} />
                                    }
                                </div>
                                <div className="site-foot-right">
                                    <div><b>The Harbor Bank of Maryland</b></div>
                                    <div><b>Community Development Corporation</b></div>
                                    <div>25 West Fayette Street</div>
                                    <div>Baltimore, Maryland 21201</div>
                                    <div>cdc@harborcdc.org</div>
                                </div>
                            </div>
                        </div>

                   
                        </div>
                        <div className="site-foot-copyright">
                            <div className="container">
                                Copyright © 2021 The Harbor Bank of Maryland Community Development Corporation
                            </div>
                        </div>

                    </footer>
                </div>
            </div>

        </>
    )
    
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                allGhostPost(filter: {tags: {elemMatch: {slug: {eq: "homepage-carousel"}}}}) {
                    edges {
                        node {
                            id
                            feature_image
                        }
                    }
                } 
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery