import React from 'react'
import { navigate } from 'gatsby'
import Recaptcha from 'react-google-recaptcha'

const RECAPTCHA_KEY = '6LfD46kaAAAAABNVSzwXp1sqFdprCQYyqenzwZLp'

const ContactForm = () => {
  const [state, setState] = React.useState({})
  const recaptchaRef = React.createRef()
  const [buttonDisabled, setButtonDisabled] = React.useState(true)

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()

    const headers = new Headers({
      "Content-Type": "application/json",
      "x-api-key": "T5LAX9Ev1334qXJJUMDJn7RdascrsXyu2rXclQNp",
    });
          
    fetch('https://wm4299d5sl.execute-api.us-east-1.amazonaws.com/default/ContactFormSubmission', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        'g-recaptcha-response': recaptchaValue,
        ...state
      }),
    })
    .then((response) => {
      if(response.status === 200) {
        navigate(form.getAttribute('action'))
      } else {
        alert('There was an issue with your submission. Please try again.')
        navigate('/contact-us')
      }
    
    })
    .catch(error => alert('There was an issue with your submission. Please try again.'))
  }

  return (
    <form
      name="contact-us"
      method="POST"
      action="/thank-you"
      onSubmit={handleSubmit}
      class="page-contact-us container"
    >
        <noscript>
            <p>This form won’t work with Javascript disabled</p>
        </noscript>

        <div class="row">
            <label for="name">Your Name (required)</label>
            <input type="text" id="name" name="name" onChange={handleChange} required />
        </div>

        <div class="row">
            <label for="email">Your Email (required)</label>
            <input type="email" name="email" id="email" onChange={handleChange} required />
        </div>

        <div class="row">
            <label for="subject">Subject</label>
            <input type="text" name="subject" id="subject" onChange={handleChange} />
            <input type="text" name="_honey" onChange={handleChange} style={{"display": "none"}} />
        </div>
  	
        <div class="row">
            <label for="message">Your Message</label>
            <textarea name="message" id="message"  onChange={handleChange}></textarea>
        </div>

        {/* Recaptcha */}
        <Recaptcha
            ref={recaptchaRef}
            sitekey={RECAPTCHA_KEY}
            size="normal"
            id="recaptcha-google"
            onChange={() => setButtonDisabled(false)}
        />

        <button class="contact-us" type="submit" disabled={buttonDisabled} style={{ "margin": "30px 0"}}>Submit</button>
    </form>
  )
}

export default ContactForm
