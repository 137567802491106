import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

/**
* Navigation component
*
* The Navigation component takes an array of your Ghost
* navigation property that is fetched from the settings.
* It differentiates between absolute (external) and relative link (internal).
* You can pass it a custom class for your own styles, but it will always fallback
* to a `site-nav-item` class.
*
*/
const Navigation = ({ data, secondary_navigation, navClass }) => (
    <>
        {data.map((navItem, i) => {
            const isServices = navItem.label === 'Our Services';

            if (navItem.url.match(/^\s?http(s?)/gi)) {
                return  <li key={i} >
                            <a className={navClass} href={navItem.url}rel="noopener noreferrer">{navItem.label} { isServices && <span className="material-icons chevron-down">expand_more</span>  } </a>
                            {
                                isServices &&
                                    <ul className="sub-nav-list">
                                        {
                                            secondary_navigation.map((secItem, idx) => {
                                                return <li key={idx}><a className={navClass} href={secItem.url} key={i} rel="noopener noreferrer">{secItem.label}</a></li>
                                            })
                                        }
                                    </ul>
                            }
                        </li>
            } else {
                return  <li key={i}>
                            <Link className={navClass} to={navItem.url}>{navItem.label} { isServices && <span className="material-icons chevron-down">expand_more</span> }</Link>
                            {
                                isServices &&
                                    <ul className="sub-nav-list">
                                        {
                                            secondary_navigation.map((secItem, idx) => {
                                                return <li key={idx}><a className={navClass} href={secItem.url} key={i} rel="noopener noreferrer">{secItem.label}</a></li>
                                            })
                                        }
                                    </ul>
                            }
                        </li>
            }
        })}
    </>
)

Navigation.defaultProps = {
    navClass: `site-nav-item`,
}

Navigation.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    navClass: PropTypes.string,
}

export default Navigation
