import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'

const LeadershipCard = ({ page }) => {
    const url = `/${page.slug}/`

    return (
        <Link to={url} className="page-card">
            <header className="page-card-header">
                {page.feature_image &&
                    <div className={'page-card-image ' + page.slug } style={{
                        backgroundImage: `url(${page.feature_image})` ,
                    }}></div>}
                {page.tags && <div className="page-card-tags"> <Tags page={page} visibility="public" autolink={false} /></div>}
                {page.featured && <span>Featured</span>}
                <h2 className="page-card-title">{page.title}</h2>
            </header>
            <b className="page-card-excerpt">{page.excerpt}</b>
        </Link>
    )
}

LeadershipCard.propTypes = {
    page: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        excerpt: PropTypes.string.isRequired,
    }).isRequired,
}

export default LeadershipCard